import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
    CHANGE_LANGUAGE,
    UPDATE_USER_PROFILE,
    CLEAR_USER_PROFILE,
} from '../actions/types';
const userProfile = localStorage.getItem('profile');
const language = localStorage.getItem('Language');
const initialState = userProfile
    ? { userProfile, language: language }
    : { userProfile: null, language: 'zh-CN' };
const userReducer = function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_USER_PROFILE:
            return {
                ...state,
                userProfile: payload.userProfile,
                jfkl: 'aen',
                language: payload.language,
            };
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: payload,
            };
        case CLEAR_USER_PROFILE:
            return {
                ...state,
                userProfile: null,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: 'userProfile',
        storage,
    },
    userReducer
);
