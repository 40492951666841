export default {
    items: [
        {
            id: 'Reports',
            title: 'Reports',
            icon: 'fa-file',
            url: '/myReports',
        },
        {
            id: 'group',
            title: 'Group',
            icon: 'fa-users',
            url: '/myGroup',
        },
        {
            id: 'profile',
            title: 'Profile',
            icon: 'fa-address-card',
            url: '/profile',
        },
    ],
};
