import {
  CHANGE_SIDEBARSHOW,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_LANGUAGE_SELECTOR,
  HIDE_LANGUAGE_SELECTOR,
} from '../actions/types'

const initialState = {
  sidebarShow: 'responsive',
  showLanguageSelector: false,
  showLoading: false,
  loadingText: '',
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case CHANGE_SIDEBARSHOW:
      return { ...state, ...payload }
    case SHOW_LANGUAGE_SELECTOR:
      return {
        ...state,
        showLanguageSelector: true,
      }
    case HIDE_LANGUAGE_SELECTOR:
      return {
        ...state,
        showLanguageSelector: false,
      }
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: true,
        loadingText: payload.text,
      }
    case HIDE_LOADING:
      return {
        ...state,
        showLoading: false,
        loadingText: '',
      }
    default:
      return state
  }
}
