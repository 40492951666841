export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const PERMISSION_DENIED_TRIGGER = 'PERMISSION_DENIED_TRIGGER';
export const PERMISSION_DENIED_DISMISS = 'PERMISSION_DENIED_DISMISS';
export const SUBSCRIPTION_PERIOD_EXPIRED_TRIGGER =
    'SUBSCRIPTION_PERIOD_EXPIRED_TRIGGER';
export const SUBSCRIPTION_PERIOD_EXPIRED_DISMISS =
    'SUBSCRIPTION_PERIOD_EXPIRED_DISMISS';
export const COMPANY_STATUS_DISABLED_TRIGGER =
    'COMPANY_STATUS_DISABLED_TRIGGER';
export const COMPANY_STATUS_DISABLED_DISMISS =
    'COMPANY_STATUS_DISABLED_DISMISS';
export const NOTIFICATION_HISTORY_MODAL_TRIGGER =
    'NOTIFICATION_HISTORY_MODAL_TRGGER';
export const NOTIFICATION_HISTORY_MODAL_DISMISS = 
    'NOTIFICATION_HISTORY_MODAL_DISMISS';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const SET_PERMISSION = 'SET_PERMISSION';
export const CLEAR_PERMISSION = 'CLEAR_PERMISSION';
export const CHANGE_SIDEBARSHOW = 'CHANGE_SIDEBARSHOW';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

// UI Framework
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_MENU_OFF = 'COLLAPSE_MENU_OFF';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const RESET = 'RESET';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = 'BOX_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
// END UI Framework

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export const UPDATE_MERCHANT_PROFILE = 'UPDATE_MERCHANT_PROFILE';
export const CLEAR_MERCHANT_PROFILE = 'CLEAR_MERCHANT_PROFILE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SHOW_LANGUAGE_SELECTOR = 'SHOW_LANGUAGE_SELECTOR';
export const HIDE_LANGUAGE_SELECTOR = 'HIDE_LANGUAGE_SELECTOR';


