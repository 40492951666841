const config = {
    owner: 'EXG',
    webTitle: 'EXG',
    shortWebTitle1: 'EXG',
    version: '0.0.1',
    serverID: window.ENV.SERVER_ID || 'Localhost',
    environment: window.ENV.SERVER_ENVIRONMENT || 'Localhost',
    apiURL: window.ENV.API_URL || 'http://localhost:8001/api',
    defaultPath: '/profile',
    basename: '', // only at build time to set, like ///able-pro/react/default
    layout: 'horizontal', // vertical, horizontal
    subLayout: 'horizontal-2', // horizontal-2
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light', // menu-dark, menu-light, dark
    // headerBackColor: 'background-blue', // background-blue, background-red, background-purple, background-info, background-green, background-dark, background-grd-blue, background-grd-red, background-grd-purple, background-grd-info, background-grd-green, background-grd-dark, background-img-1, background-img-2, background-img-3, background-img-4, background-img-5, background-img-6
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
    timezone: 'Asia/Singapore',
};
export default config;
