import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import userProfile from './userProfile';
import store from './store';
import ui from './ui';

export default combineReducers({
    auth,
    message,
    userProfile,
    store,
    ui,
});
