import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, AUTH_FAIL } from '../actions/types';

const token = localStorage.getItem('userToken');

const initialState = token
    ? {
          isLoggedIn: true,
          token,
          profile: null,
      }
    : {
          isLoggedIn: false,
          token: null,
          profile: null,
      };

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                profile: payload.profile,
                token: payload.token,
            };
        case AUTH_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                profile: null,
                token: null,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                profile: null,
                token: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                profile: null,
                token: null,
            };

        default:
            return state;
    }
}
