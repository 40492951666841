import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem } from 'react-bootstrap';
import navigation from '../menu-items';

const MobileNav = () => {
    const { t } = useTranslation();

    return (
        <nav
            id="mobileNav"
            className="navbar fixed-bottom navbar-light p-0"
            role="navigation"
        >
            <Nav className="w-100">
                <div className="d-flex flex-row justify-content-around w-100">
                    {navigation.items.map((menu) => {
                        return (
                            <NavItem>
                                <a href={menu.url} className="nav-link">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <i
                                            className={`fas fa-lg my-1 ${menu.icon}`}
                                        />
                                        <div className="text-center">
                                            {t(menu.title)}
                                        </div>
                                    </div>
                                </a>
                            </NavItem>
                        );
                    })}
                </div>
            </Nav>
        </nav>
    );
};

export default MobileNav;
