import Aux from './hoc/_Aux';
import Loadable from 'react-loadable';
import Loader from './layout/Loader';
import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ScrollToTop from './layout/ScrollToTop';
import '../node_modules/font-awesome/scss/font-awesome.scss';
import { Router, Switch, Route } from 'react-router-dom';
import { history } from './helpers/history';
import moment from 'moment-timezone';
import config from './config';
import { useTranslation } from 'react-i18next';
import SimpleReactLightbox from 'simple-react-lightbox';
import MobileNav from 'layout/mobileNav';

const PublicLayout = Loadable({
    loader: () => import('./layout/PublicLayout'),
    loading: Loader,
});

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader,
});

moment.tz.setDefault(config.timezone);

const App = () => {
    const { language } = useSelector((state) => state.userProfile);
    const { isLoggedIn } = useSelector((state) => state.auth);

    const { i18n } = useTranslation();
    useEffect(() => {
        if (i18n.language !== language) i18n.changeLanguage(language);
    }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SimpleReactLightbox>
            <Aux>
                <Router history={history}>
                    <ScrollToTop>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                <Route
                                    exact
                                    path="/login"
                                    component={PublicLayout}
                                />
                                {/* <Route
                                    exact
                                    path="/signUp"
                                    component={PublicLayout}
                                />
                                <Route
                                    exact
                                    path="/resetPassword"
                                    component={PublicLayout}
                                /> */}
                                <Route path="/" component={AdminLayout} />
                            </Switch>

                            {isLoggedIn ? <MobileNav /> : null}
                        </Suspense>
                    </ScrollToTop>
                </Router>
            </Aux>
        </SimpleReactLightbox>
    );
};

export default App;
